<template>
  <modal
    :width="474"
    :adaptive="true"
    :shiftX="0"
    height="auto"
    :styles="{margin: 'auto'}"
    class="shanivCustomerAgentModal"
    name="shanivCustomerAgentModal"
  >
    <div class="modal-content">
      <button
        class="button modal-default__close"
        @click="closeModal"
      >
        <img src="/assets/img/close.svg" alt="" />
      </button>
      <div v-for="(companyCustomerData, company) in customerData" :key="company" class="d-flex customer-agent-info-wrapper">
        <div class="text-center agent-code-column customer-agent-info-row">
          <span>{{ companyCustomerData.AGENTCODE }}</span>
        </div>
        <div class="">
          <div class="customer-agent-logo-row customer-agent-info-row">
            <span class="customer-info-label text-bold">{{ $t("Agent") }}</span>
            <span class="customer-info-data customer-info-data-img"><img :src="`/new-assets/img/${company}-logo.png`"/></span>
          </div>
          <div class="customer-agent-info-row">
            <span class="customer-info-label">{{ $t("Agent Name") }}:</span>
            <span class="customer-info-data text-bold">{{ customerAgentName(companyCustomerData) }}</span>
          </div>
          <div class="customer-agent-info-row">
            <span class="customer-info-label">{{ $t("Agent Phone number") }}:</span>
            <span class="customer-info-data text-bold">{{ customerAgentPhone(companyCustomerData) }}</span>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import CartMixin from '../utility/cart_mixin';

    export default {
        name: "ShanivCustomerAgentModal",
        mixins: [CartMixin],
        components: {
        },
        props: {},
        data() {
            return {
            }
        },
        computed: {
            ...mapState({
            })
        },
        async created() {
        },
        methods: {
            closeModal() {
                this.$modal.hide('shanivCustomerAgentModal');
            },
        }

    }
</script>

<style lang="scss" scoped>
  .shanivCustomerAgentModal {
    .modal-default__close {
      left: unset;
      right: 18px;
    }
  }
  .rtl-type {
    .shanivCustomerAgentModal {
      .modal-default__close {
        left: 18px;
        right: unset;
      }
    }
  }
  .shanivCustomerAgentModal {
    .modal-content {
      font-size: 16px;
      padding: 28px 14px;
      .customer-agent-info-wrapper {
        padding: 10px 0;
        &:not(:last-child) {
          border-bottom: 1px solid #707070;
        }
      }
      .agent-code-column {
        flex-basis: 60px;
      }
      .customer-agent-info-row {
        line-height: 49px;
        span {
          display: inline-block;
          &.customer-info-label {
            padding-left: 14px;
          }
          &.customer-info-data {
            padding-left: 14px;
          }
          &.customer-info-data-img {
            vertical-align: sub;
            padding: 0 12px;
          }
        }
      }
      .customer-agent-logo-row {
        span {
          &.customer-info-label {
            padding-left: 0;
          }
        }
        img {
          height: 28px;
        }
      }
    }
  }
</style>