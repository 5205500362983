<template>
  <div class="tabs d-flex justify-content-between">
    <template v-for="(tab, i) in tabs">
      <div
        class="tab"
        :key="i"
        :class="{ active: activeTab == tab.id }"
        @click="changeTab(tab)"
      >
        <span v-if="i == 0">
          {{ tab.text }} (
          <span class="color-purple">{{ cartTotalQuantity }}</span> )
        </span>
        <span v-else>
          {{ tab.text }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'CartTable',
  props: {
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tabs: [
        {
          id: 0,
          text: this.$t('Shopping cart'),
          router_name: "CartSystem"
        },
        {
          id: 1,
          text: this.$t('Order History'),
          router_name: "CartOrderHistory"
        },
        {
          id: 2,
          text: this.$t('Open invitations'),
          router_name: "CartTempOrder"
        },
        // {
        //   id: 3,
        //   text: this.$t('Repurchase'),
        //   router_name: "CartOrderHistory"
        // },
        // {
        //   id: 4,
        //   text: this.$t('Remove all items'),
        //   router_name: "CartOrderHistory"
        // },
      ],
    };
  },
  computed: {
    ...mapGetters('cartSystem', {
      cartTotalQuantity: 'cartTotalQuantity'
    }),
  },
  methods: {
    changeTab({ router_name }) {
      this.$router.push({ name: router_name });
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  margin-bottom: 1.5rem;
  .tab {
    padding: 5px 0;
    text-align: center;
    width: 100%;
    border-bottom: 3px solid white;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    &.active {
      border-bottom: 3px solid #74287a;
    }
  }
}
</style>