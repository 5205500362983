<template>
  <div
    class="main-content page_bg_grey blank-aside-js cart-page-bg"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <div class="content-margin">
      <banner-section location="shaniv_cart-top"/>
      <div class="cart-content mt-15 destop-design">
        <h2 class="title fs23 mb-10">
          {{ $t("Search for a product") }}
        </h2>
        <div class="d-flex justify-content-between">
          <div class="products-container small-width">
            <cart-tab :activeTab="0"></cart-tab>
            <button
              class="primary-btn remove-all-btn bg-red7 mb-5"
              @click="removeAll()"
              v-if="cartTotalQuantity"
            >
              <img
                src="/new-assets/icon/trash-red.png"
                alt=""
                class="pointer"
              />
              {{ $t('Remove all items') }}
            </button>
            <div class="table-responsive">
              <template v-if="cartTotalQuantity">
                <table class="table" v-for="(company, h) in cart"
                       :key="'desktop_company_' + h">
                  <tr>
                    <th class="text-center fs14 color-purple py-5">
                      {{ $t('Supplier ID') }}
                    </th>
                    <th class="text-center fs14 color-purple py-5">
                      {{ $t('Products') }}
                    </th>
                    <th class="text-center fs14 color-purple py-5">
                      {{ $t('Price per unit') }}
                    </th>
                    <th class="text-center fs14 color-purple py-5">
                      {{ $t('Package Quantity') }}
                    </th>
                    <th class="text-center fs14 color-purple py-5">
                      {{ $t('Price') }}
                    </th>
                    <th class="text-center fs14 color-purple py-5">
                      {{ $t('Quantity') }}
                    </th>
                    <th class="text-center fs14 color-purple py-5">
                      {{ $t('Sum') }}
                    </th>
                  </tr>
                  <template v-if="company.products">
                    <tr
                      v-for="(product, j) in Object.values(company.products)"
                      :key="'desktop_product_' + product.id"
                      class="odd"
                      :class="{'with-out-of-stock': itemIsOutOfStock(product)}"
                    >
                      <td
                        class="text-center vertical-middle company-logo-col"
                        :rowspan="companyLogoRowspan(company)"
                        v-if="j === 0"
                      >
                        <div>
                          <img
                            v-if="h == 'sastech'"
                            class="company-logo"
                            src="/new-assets/img/2סאסאטק.png"
                            alt=""
                          />
                          <img
                            v-else-if="h == 'shaniv'"
                            class="company-logo"
                            src="/new-assets/img/shaniv-logo.png"
                            alt=""
                          />
                        </div>
                      </td>

                      <td>
                        <div class="product-content">
                          <img
                            :src="
                              product.image || '/new-assets/img/shaniv-logo.png'
                            "
                            alt=""
                          />

                          <div class="product-description">
                            <router-link
                              :to="{
                                name: 'SastechProduct',
                                params: {
                                  company: product.company,
                                  id: product.id,
                                },
                              }"
                            >
                              <p
                                class="
                                  color-purple
                                  fs16
                                  weight-700
                                  limited-lines-number
                                  lines-number-2
                                  break-word
                                "
                                :title="product[currentLang + '_name']"
                              >
                                {{ product[currentLang + '_name'] }}
                                {{ product[currentLang + '_name'] }}
                              </p>
                            </router-link>
                            <p
                              class="
                                fs14
                                limited-lines-number
                                lines-number-3
                                break-word
                              "
                              :title="product[currentLang + '_description']"
                            >
                              {{ product[currentLang + '_description'] }}
                            </p>
                            <p class="fs12 weight-700 mt-5 mb-7">
                              {{ $t('Barcode') }}:
                              {{ product.BARCODE || '-' }}
                            </p>
                          </div>
                        </div>
                      </td>

                      <td class="text-center">
                        <div class="weight-700 fs16">
                          <i>{{ product.DISCOUNT_VATPRICE | formatPrice }} ₪</i>
                        </div>
                      </td>

                      <td class="text-center">
                        <div class="weight-700 fs16">
                          <i>{{ getProductPackageQuantity(product) }}</i>
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="weight-700 fs16">
                          <i
                            >{{
                              getProductPackageDiscountPrice(product)
                                | formatPrice
                            }}
                            ₪</i
                          >
                        </div>
                      </td>
                      <td>
                        <div class="action-column">
                          <HcartNum
                            :num="product.quantity"
                            class="mt-10 mb-5"
                            @increase="onIncreaseQuality(product, 'products')"
                            @decrease="onDecreaseQuality(product, 'products')"
                          ></HcartNum>
                          <div v-if="itemIsOutOfStock(product)" class="out-of-stock-quantity color-red4 mb-5">({{product.out_of_stock_quantity}})</div>
                          <button
                            class="btn-remove mb-5"
                            v-on:click="
                              deleteProductToCart({
                                company: product.company,
                                type: 'products',
                                id: product.id,
                              })
                            "
                          >
                            <img
                              src="/new-assets/icon/trash-red.png"
                              alt=""
                              class="pointer"
                            />
                          </button>
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="weight-700 fs18">
                          {{ calculateProductQuantityPrice(product) }} ₪
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="company.packages">
                    <template
                      v-for="(product, j) in Object.values(company.packages)"
                    >
                      <tr
                        :key="'desktop_package_' + product.id"
                        class="odd"
                        :class="{ 'package-type': true, 'with-out-of-stock': itemIsOutOfStock(product)}"
                      >
                        <td
                          class="text-center vertical-middle company-logo-col"
                          :rowspan="companyLogoRowspan(company)"
                          v-if="!company.products || Object.values(company.products).length == 0 && j === 0"
                        >
                          <div>
                            <img
                              v-if="h == 'sastech'"
                              class="company-logo"
                              src="/new-assets/img/2סאסאטק.png"
                              alt=""
                            />
                            <img
                              v-else-if="h == 'shaniv'"
                              class="company-logo"
                              src="/new-assets/img/shaniv-logo.png"
                              alt=""
                            />
                          </div>
                        </td>
                        <td class="pointer">
                          <div class="product-content m-0">
                            <img
                              :src="
                                product.image ||
                                '/new-assets/img/shaniv-logo.png'
                              "
                              alt=""
                            />
                            <div class="product-description">
                              <router-link
                                :to="{
                                  name: 'SastechPackage',
                                  params: {
                                    company: product.company,
                                    id: product.id,
                                  },
                                }"
                              >
                                <p
                                  class="
                                    color-yellow
                                    fs16
                                    weight-700
                                    limited-lines-number
                                    lines-number-2
                                    break-word
                                  "
                                  :title="product.name"
                                >
                                  {{ product.name }}
                                </p>
                              </router-link>
                              <p class="description fs14"></p>
                              <p
                                @click="toggleExpand(j)"
                                class="
                                  text-decoration-underline
                                  fs16
                                  color-green
                                  weight-700
                                "
                              >
                                {{ toggleExpandButtonText(j) }}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="weight-700 fs16">
                            <i>{{ product.DISCOUNT_VATPRICE }} ₪</i>
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="weight-700 fs16"><i>1</i></div>
                        </td>
                        <td class="text-center">
                          <div class="weight-700 fs16">
                            <i>{{ product.DISCOUNT_VATPRICE }} ₪</i>
                          </div>
                        </td>
                        <td>
                          <div class="action-column">
                            <HcartNum
                              :num="product.quantity"
                              class="mt-10 mb-5"
                              @increase="onIncreaseQuality(product, 'packages')"
                              @decrease="onDecreaseQuality(product, 'packages')"
                            ></HcartNum>
                            <div v-if="itemIsOutOfStock(product)" class="out-of-stock-quantity color-red4 mb-5">({{product.out_of_stock_quantity}})</div>
                            <button
                              class="btn-remove mb-5"
                              v-on:click="
                                deleteProductToCart({
                                  company: product.company,
                                  type: 'packages',
                                  id: product.id,
                                })
                              "
                            >
                              <img
                                      src="/new-assets/icon/trash-red.png"
                                      alt=""
                                      class="pointer"
                              />
                            </button>
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="weight-700 fs18">
                            {{ calculateProductQuantityPrice(product) }} ₪
                          </div>
                          <div
                            v-if="calculateProductQuantityDiscount(product) > 0"
                          >
                            <p class="fs12 color-red8">
                              {{ $t('Full price') }}:
                              {{
                                calculateProductQuantityPrice({
                                  DISCOUNT_VATPRICE: product.VATPRICE,
                                  qtySteps: 1,
                                  quantity: product.quantity,
                                })
                              }}
                              ₪
                            </p>
                            <p class="fs12 color-green2">
                              {{ $t('Savings') }}:
                              {{ calculateProductQuantityDiscount(product) }} ₪
                            </p>
                          </div>
                        </td>
                      </tr>
                      <template v-if="product.products">
                        <tr
                          v-for="(subProduct, k) in Object.values(product.products)"
                          class="expanded"
                          v-show="opened.includes(j)"
                          :key="'desktop_package_' + product.id + '_product_' + subProduct.id"
                          :class="{ 'package-type': true, 'with-out-of-stock': itemIsOutOfStock(product)}"
                        >
                          <td>
                            <div class="product-content m-0">
                              <img
                                :src="
                                  subProduct.image ||
                                  '/new-assets/img/shaniv-logo.png'
                                "
                              />
                              <div class="product-description">
                                <p
                                  class="
                                    color-yellow
                                    fs14
                                    weight-700
                                    limited-lines-number
                                    lines-number-2
                                    break-word
                                  "
                                  :title="
                                    subProduct.ShanivProduct
                                      ? subProduct.ShanivProduct[
                                          currentLang + '_name'
                                        ]
                                      : ''
                                  "
                                >
                                  {{
                                    subProduct.ShanivProduct
                                      ? subProduct.ShanivProduct[
                                          currentLang + '_name'
                                        ]
                                      : ''
                                  }}
                                </p>
                                <p
                                  class="
                                    fs12
                                    limited-lines-number
                                    lines-number-3
                                    break-word
                                  "
                                  :title="
                                    subProduct.ShanivProduct
                                      ? subProduct.ShanivProduct[
                                          currentLang + '_description'
                                        ]
                                      : ''
                                  "
                                >
                                  {{
                                    subProduct.ShanivProduct
                                      ? subProduct.ShanivProduct[
                                          currentLang + '_description'
                                        ]
                                      : ''
                                  }}
                                </p>
                                <p class="fs12 weight-700 mt-5 mb-7">
                                  {{ $t('Barcode') }}:
                                  {{
                                    subProduct.ShanivProduct &&
                                    subProduct.ShanivProduct.BARCODE
                                      ? subProduct.ShanivProduct.BARCODE
                                      : '-'
                                  }}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="weight-700 fs16">
                              <i
                                >{{
                                  subProduct.ShanivProduct &&
                                  subProduct.ShanivProduct.DISCOUNT_VATPRICE
                                    ? subProduct.ShanivProduct.DISCOUNT_VATPRICE
                                    : 0
                                }}
                                ₪</i
                              >
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="weight-700 fs16">
                              <i>{{
                                getProductPackageQuantity(
                                  subProduct.ShanivProduct,
                                )
                              }}</i>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="weight-700 fs16">
                              <i
                                >{{
                                  getProductPackageDiscountPrice(
                                    subProduct.ShanivProduct,
                                  )
                                }}
                                ₪</i
                              >
                            </div>
                          </td>
                          <td>
                            <div class="action-column">
                              <HcartNum
                                :num="subProduct.quantity"
                                :disabled="true"
                                class="mt-10 mb-5"
                              ></HcartNum>
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="weight-700 fs18">0 ₪</div>
                          </td>
                        </tr>
                      </template>
                    </template>
                  </template>
                </table>
              </template>

              <div v-else class="cart-content">
                <router-link
                  :to="{
                    name: 'Sastech',
                  }"
                  tag="a"
                  exact
                  class="text-decoration-none"
                >
                  <button class="add-product">
                    {{ $t('Add products') }}
                  </button>
                </router-link>
              </div>
            </div>
          </div>
          <div class="summary-container" v-if="cartTotalQuantity">
            <div class="summary-box">
              <h2 class="fs20">{{ $t('Order summary') }}</h2>
              <div class="supplier-container">
                <div
                  class="
                    credit-limit
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <div>
                    <img
                      class="company-logo"
                      src="/new-assets/img/shaniv-logo.png"
                      alt=""
                      v-if="cart.shaniv"
                    />
                    <img
                      class="company-logo"
                      src="/new-assets/img/2סאסאטק.png"
                      alt=""
                      v-if="cart.sastech"
                    />
                  </div>
                  <div>
                    <p v-if="false" class="fs14 d-flex justify-content-between">
                      {{ $t('Credit line for utilization') }}:
                      <b>₪{{ customerBalance }}</b>
                    </p>
                    <p class="fs14 d-flex justify-content-between">
                      {{ $t('Minimum amount per order is {num}', { num: '' }) }}:
                      <b class="color-red9">₪{{ minOrderAmount }}</b>
                    </p>
                  </div>
                </div>

                <div class="discount">
                  <div class="flex fs14">
                    <p>{{ $t('Order value') }}:</p>
                    <p class="weight-700">₪{{ cartTotalVATPrice }}</p>
                  </div>
                  <div v-for="(company_data, company_name) in cart"
                    :key="'company_' + company_name"
                    class="flex fs14"
                  >
                    <p>{{ $t('Order value') }} ({{company_name}}):</p>
                    <p class="weight-700">₪{{ cartTotalDiscountVATPriceByCompany(company_name) }}</p>
                  </div>
                  <div
                    v-if="cartTotalDiscount && cartTotalDiscount > 0"
                    class="flex fs14 color-green3 weight-700"
                  >
                    <p>{{ $t('Savings') }}:</p>
                    <p>₪{{ cartTotalDiscount }}</p>
                  </div>
                </div>
                <div class="order-summary fs14">
                  <div class="flex">
                    <div>
                      <p class="mb-2">{{ $t('Order summary') }}:</p>
                      <p class="mb-2">{{ $t('Including VAT') }}:</p>
                      <p v-if="customerData && Object.values(customerData).length" class="mb-2 cursor-pointer" @click="openShanivCustomerAgentModal">
                        {{ $t('Agent') }}:
                        <span><b>{{ customerAgentNames() }}</b></span>
                      </p>
                      <p class="mb-2">
                        {{ $t('The day the order arrives') }}: <b>{{ dueDate }}</b>
                      </p>
                    </div>
                    <div class="fs18 weight-700">
                      <p>₪{{ cartTotalDiscountVATPrice }}</p>
                      <p>₪{{ cartTotalDiscountCalcVAT }}</p>
                    </div>
                  </div>
                  <div
                    class="
                      d-flex
                      justify-content-between
                      align-items-end
                      summary-action
                    "
                  >
                    <p
                      class="text-decoration-underline pointer"
                      @click="moveCartToTempOrder()"
                    >
                      {{ $t('Save and complete an order later') }}
                    </p>
                    <button
                      v-if="!minOrderAmountError"
                      class="
                        primary-btn primary-btn_sm
                        bg-orange
                        line-height1
                        m-0
                      "
                      @click="createOrder"
                    >
                      {{ $t('Make a Reservation') }}
                    </button>
                    <router-link
                      v-else
                      :to="{ name: 'Sastech' }"
                      tag="button"
                      exact
                      class="
                        text-decoration-none
                        primary-btn primary-btn_sm
                        bg-blue
                        m-0
                      "
                    >
                      {{ $t('Add products') }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- --------------------------------mobile design start---------------------- -->
      <div class="mobile-design">
        <div
          class="search-block d-flex justify-content-center align-items-center"
        >
          <input type="text" class="input"
          :placeholder="$t('Shopping cart')">
          <button class="primary-btn df-c">
            <img src="/assets/img/icons/search-light-white.svg" alt="">
          </button>
        </div>
        <div v-show="!isModalVisible">
          <h4 class="items-numbers">
            {{ $t("{NUM} items in your shopping cart", {NUM: cartTotalQuantity}) }}
          </h4>
          <div class="action-block d-flex justify-content-between">
            <p class="color-red10" @click="removeAll()">{{ $t('Remove all') }}</p>
            <p class="color-blue5" @click="toggleMobileExpandAll">{{ $t('Expand all') }}</p>
            <p class="color-green2" @click="moveCartToTempOrder()">{{ $t('Save for later') }}</p>
          </div>
          <template v-if="cartTotalQuantity">
            <div v-for="(company, h) in cart" 
              :key="'mobile_company_' + h"
              class="company-block" 
              :class="h == 'sastech'? 'sastech-block' : 'shaniv-block'"
            >
              <img
                v-if="h == 'sastech'"
                class="company-logo"
                src="/new-assets/img/2סאסאטק.png"
                alt=""
              />
              <img
                v-else-if="h == 'shaniv'"
                class="company-logo"
                src="/new-assets/img/shaniv-logo.png"
                alt=""
              />
              <template v-if="company.products">
                <div 
                  class="product-block d-flex justify-content-between p-relative"
                  :class="{'with-out-of-stock': itemIsOutOfStock(product)}"
                  v-for="(product, j) in Object.values(company.products)"
                  :key="'mobile_product_' + product.id"
                >
                  <div class="d-flex">
                    <img
                      :src="product.image || '/new-assets/img/shaniv-logo.png'"
                      alt=""
                      class="product-img"
                    />
                    <div>
                      <router-link
                        :to="{
                          name: 'SastechProduct',
                          params: {
                            company: product.company,
                            id: product.id,
                          },
                        }"
                      >
                        <p
                          class="
                            color-purple
                            fs16
                            weight-700
                            limited-lines-number
                            lines-number-2
                            break-word
                          "
                          :title="product[currentLang + '_name']"
                        >
                          {{ product[currentLang + '_name'] }}
                        </p>
                      </router-link>
                      <div v-if="mOpened.includes(product.id)">
                        <p class="limited-lines-number lines-number-2 break-word">
                          {{ product[currentLang + '_description'] }}
                        </p>
                        <p class="fs12 mt-5">{{ $t('Package quantity') }}: {{ getProductPackageQuantity(product) }}</p>
                        <p class="fs12">{{ $t('Price per unit') }}: {{ getProductPackageDiscountPrice(product) | formatPrice }} ₪</p>
                      </div>
                      <div class="quantity-block">
                        <HcartNum
                                :num="product.quantity"
                                class="mt-10 mb-5 hcart-num"
                                @increase="onIncreaseQuality(product, 'products')"
                                @decrease="onDecreaseQuality(product, 'products')"
                        ></HcartNum>
                        <div v-if="itemIsOutOfStock(product)" class="out-of-stock-quantity color-red4 mb-5 text-center">({{product.out_of_stock_quantity}})</div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center mb-20" style="min-width: 80px">
                    <h4>{{ calculateProductQuantityPrice(product) }} ₪</h4>
                    <p class="fs12" v-if="mOpened.includes(product.id)">
                      {{ $t('Full price') }}: {{ calculateProductQuantityPrice({ DISCOUNT_VATPRICE: product.VATPRICE, qtySteps: 1, quantity: product.quantity }) }} ₪</p>
                    <p
                      class="fs12 color-green2"
                      v-if="calculateProductSavingPrice(product)>0"
                    >
                      {{ $t('Savings') }}: {{ calculateProductSavingPrice(product) }} ₪
                    </p>
                    <p 
                      class="fs11 text-decoration-underline color-red10"
                      v-on:click="
                          deleteProductToCart({
                            company: product.company,
                            type: 'products',
                            id: product.id,
                          })
                        "
                    >
                      {{ $t("Remove") }}
                    </p>
                  </div>
                  <div class="detail-btn df-c" @click="mobileToggleExpand(product.id)">
                    <img src="/assets/img/icons/chevron-down.svg" v-if="!mOpened.includes(product.id)" alt="">
                    <img src="/assets/img/icons/chevron-up.svg" v-else alt="">
                  </div>
                </div>
              </template>
              <template v-if="company.packages">
                <div
                  class="package-block d-flex justify-content-between p-relative"
                  :class="{'with-out-of-stock': itemIsOutOfStock(product)}"
                  v-for="(product, j) in Object.values(company.packages)"
                  :key="'mobile_package_' + product.id"
                >
                  <img
                    :src="
                      product.image ||
                      '/new-assets/img/shaniv-logo.png'
                    "
                    alt=""
                    class="package-img"
                  />
                  <div class="text-center mb-15 mt-5">
                    <h4>{{ calculateProductQuantityPrice(product) }} ₪</h4>
                    <p 
                      class="fs12 color-green2"
                      v-if="calculateProductSavingPrice(product)>0"
                    >
                      {{ $t('Savings') }}:
                      {{ calculateProductQuantityDiscount(product) }} ₪
                    </p>
                    <HcartNum
                      :num="product.quantity"
                      class="mt-5 mb-5 hcart-num"
                      @increase="onIncreaseQuality(product, 'packages')"
                      @decrease="onDecreaseQuality(product, 'packages')"
                    ></HcartNum>
                    <div v-if="itemIsOutOfStock(product)" class="out-of-stock-quantity color-red4 mb-5 text-center">({{product.out_of_stock_quantity}})</div>
                     <p 
                      class="fs11 text-decoration-underline color-red10"
                      v-on:click="
                          deleteProductToCart({
                            company: product.company,
                            type: 'packages',
                            id: product.id,
                          })
                        "
                    >
                      {{ $t("Remove") }}
                    </p>
                    <router-link
                      :to="{
                        name: 'SastechPackage',
                        params: { company: product.company, id: product.id },
                      }"
                    >
                      <p class="detail-btn">{{ $t('Deal details') }}</p>
                    </router-link>  
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>

        <MobileFiexedBottom></MobileFiexedBottom>
      </div>
    </div>
    <ShanivCustomerAgentModal></ShanivCustomerAgentModal>

    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import HcartNum from '../../components/HcartNum';
import CartTab from '../../components/cart/CartTab.vue';
import CartFooterMenu from '../../components/cart/CartFooterMenu.vue';
import MobileFiexedBottom from '../../components/cart/MobileFiexedBottom.vue';
import ShanivCustomerAgentModal from "../../components/ShanivCustomerAgentModal.vue";

import CartMixin from '../../utility/cart_mixin';
import BannerSection from "../../components/BannerSection";

export default {
  name: 'CartSystem',
  mixins: [CartMixin],
  components: {
    BannerSection,
    HcartNum,
    CartTab,
    CartFooterMenu,
    MobileFiexedBottom,
    ShanivCustomerAgentModal
  },
  data() {
    return {
      company: 'shaniv',
      opened: [],
      mOpened: [],
      isModalVisible: false,
      initialized: false,
      // expanded: [],
    };
  },
  computed: {
    ...mapState({
      packageCounter: (state) => state.cartSystem.packageCounter,
      createOrderLoading: (state) => state.cartSystem.createOrderLoading,
      createOrderError: (state) => state.cartSystem.createOrderError,
      tempOrder: (state) => state.cartSystem.tempOrder,
      minOrderAmount: (state) => state.shaniv_customer.minOrderAmount,
      customerBalance: (state) => state.shaniv_customer.customerBalance,
      package_list: (state) => state.shaniv_management.package_list,
      products_list: (state) => state.productShaniv.products,
      getProductsInProgress: (state) =>
        state.productShaniv.getProductsInProgress,
      shanivManagementLoading: (state) => state.shaniv_management.loading,
      getCustomerDataLoading: (state) => state.shaniv_customer.loading,
    }),
    ...mapGetters('cartSystem', {
      cart: 'cart',
      products: 'products',
      packages: 'packages',
      productsTotalQuantity: 'productsTotalQuantity',
      productsTotalPrice: 'productsTotalPrice',
      productsTotalVATPrice: 'productsTotalVATPrice',
      packagesTotalQuantity: 'packagesTotalQuantity',
      packagesTotalPrice: 'packagesTotalPrice',
      packagesTotalVATPrice: 'packagesTotalVATPrice',
      cartTotalQuantity: 'cartTotalQuantity',
      cartTotalPrice: 'cartTotalPrice',
      cartTotalVATPrice: 'cartTotalVATPrice',
      cartTotalVAT: 'cartTotalVAT',
      cartTotalDiscountVAT: 'cartTotalDiscountVAT',
      cartTotalDiscountCalcVAT: 'cartTotalDiscountCalcVAT',
      cartTotalDiscount: 'cartTotalDiscount',
      // cartTotalUniqueCount: 'cartTotalUniqueCount',
      cartTotalDiscountPrice: 'cartTotalDiscountPrice',
      cartTotalDiscountVATPrice: 'cartTotalDiscountVATPrice',
      cartTotalDiscountVATPriceByCompany: 'cartTotalDiscountVATPriceByCompany',
      minOrderAmountError: 'minOrderAmountError',
    }),
    dueDate() {
      let dueDate = moment();
      if (dueDate.hour() < 15) {
        dueDate = dueDate.add(1, 'day');
      } else {
        dueDate = dueDate.add(2, 'day');
      }

      return dueDate.format('DD/MM/YYYY dddd');
    },
    loading() {
      return (
        this.shanivManagementLoading ||
        this.getProductsInProgress ||
        this.createOrderLoading ||
        this.getCustomerDataLoading
      );
    },
  },
  methods: {
    ...mapActions('cartSystem', {
      addProductToCart: 'addProductToCart',
      addProductsToCart: 'addProductsToCart',
      deleteProductToCart: 'deleteProductToCart',
      deleteProductToCartByQuantity: 'deleteProductToCartByQuantity',
      initCart: 'initCart',
      fetchActualCartData: 'fetchActualCartData',
      createOrder: 'createOrder',
      saveTempOrder: 'saveTempOrder',
      clearCart: 'clearCart',
      getTempOrder: 'getTempOrder',
      updateCart: 'updateCart',
    }),
    ...mapActions('shaniv_management', {
      getPackageList: 'getPackageList',
    }),
    ...mapActions('productShaniv', {
      getProducts: 'getProducts',
    }),
    itemIsOutOfStock(item) {
      return this.initialized && item.out_of_stock_quantity && item.out_of_stock_quantity > 0;
    },
    companyLogoRowspan(company_items) {
      let rowspan = 0;

      if ('products' in company_items && company_items.products) {
        rowspan += Object.values(company_items.products).length;
      }

      if ('packages' in company_items && company_items.products) {
        for (let packageObj of Object.values(company_items.packages)) {
          rowspan +=
            1 +
            (packageObj.products ? Object.values(packageObj.products).length : 0);
        }
      }


      return rowspan;
    },
    toggleExpand(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },
    mobileToggleExpand(id) {
      const index = this.mOpened.indexOf(id);
      if (index > -1) {
        this.mOpened.splice(index, 1);
      } else {
        this.mOpened.push(id);
      }
    },
    toggleMobileExpandAll() {
      this.mOpened = [];
      for (let key in this.cart) {
        for (let key2 in this.cart[key].products) {
          this.mOpened.push(this.cart[key].products[key2].id);
        }
      }
    },
    toggleExpandButtonText(id) {
      return this.opened.indexOf(id) === -1
        ? this.$t('More')
        : this.$t('Hide');
    },
    getProductPackageQuantity({ id, qtySteps }) {
      return qtySteps && qtySteps > 0 ? qtySteps : 1;
    },
    getProductPackageDiscountPrice({ id, qtySteps, DISCOUNT_VATPRICE }) {
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;
      DISCOUNT_VATPRICE =
        DISCOUNT_VATPRICE && DISCOUNT_VATPRICE > 0 ? DISCOUNT_VATPRICE : 0;

      return (
        Math.round((DISCOUNT_VATPRICE * qtySteps + Number.EPSILON) * 100) / 100
      );
    },
    getProductPackagePrice({ id, qtySteps, DISCOUNT_VATPRICE, VATPRICE }) {
      VATPRICE = VATPRICE >= DISCOUNT_VATPRICE ? VATPRICE : DISCOUNT_VATPRICE;
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;

      return Math.round((VATPRICE * qtySteps + Number.EPSILON) * 100) / 100;
    },
    calculateProductQuantityPrice({ DISCOUNT_VATPRICE, quantity, qtySteps }) {
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;

      return (
        Math.round(
          (DISCOUNT_VATPRICE * quantity * qtySteps + Number.EPSILON) * 100,
        ) / 100
      );
    },
    calculateProductSavingPrice({ DISCOUNT_VATPRICE, VATPRICE, quantity, qtySteps }) {
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;

      return (
        Math.round(
          ((VATPRICE - DISCOUNT_VATPRICE) * quantity * qtySteps + Number.EPSILON) * 100,
        ) / 100
      );
    },
    calculateProductQuantityDiscount({
      VATPRICE,
      DISCOUNT_VATPRICE,
      quantity,
      qtySteps,
    }) {
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;
      let discountPrice = this.calculateProductQuantityPrice({
        DISCOUNT_VATPRICE,
        quantity,
        qtySteps,
      });
      let price = this.calculateProductQuantityPrice({
        DISCOUNT_VATPRICE: VATPRICE,
        quantity,
        qtySteps,
      });

      return Math.round((price - discountPrice + Number.EPSILON) * 100) / 100;
    },
    onIncreaseQuality(item, type) {
      let quantity = 1;
      this.addProductsToCart({
        product: item,
        quantity: quantity,
        company: item.company,
        type: type,
      });
    },
    onDecreaseQuality(item, type) {
      let quantity = 1;
      this.deleteProductToCartByQuantity({
        product: item,
        quantity: quantity,
        company: item.company,
        type: type,
      });
    },
    moveCartToTempOrder() {
      this.saveTempOrder().then((res) => {
        if (res == 'success') {
          this.clearCart();
          this.$router.replace({ name: 'Sastech' });
        }
      });
    },
    removeAll() {
      this.clearCart();
    },
    openSummaryModal() {
      this.isModalVisible = true;
      $("html").scrollTop(0);
      $("html").css("overflow", "hidden");
    },
    closeSummaryModal() {
      this.isModalVisible = false;
      $("html").css("overflow", "auto");
    },
    openShanivCustomerAgentModal() {
      this.$modal.show('shanivCustomerAgentModal');
    },
  },

  async created() {
    this.initCart();
    await this.fetchActualCartData();
    await this.updateCart();
    this.initialized = true;
  },
};
</script>
<style lang="scss">
.mobile-design {
  .company-block {
    .hcart-num {
      .product-num {
        border: 1px solid #707070;
      }
    } 
  }
}
@media (max-width: 480px) {
  .cart-page-bg {
    background-color: #fff !important;
  }
  .cart-banner {
    .VueCarousel-slide {
      img {
        height: 150px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import '../../assets/scss/_table';
.cart-content {
  padding-bottom: 50px;
  tr {
    background: #e0dad9;
    &.odd {
      // background: #d6d9df;
      background: #dbdcde;
    }
    &.expanded {
      background: #d6d9df;
    }
    &.with-out-of-stock {
      td:not(.company-logo-col) {
        background-color: #EB9391;
      }
    }
  }
  .action-column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .company-logo {
    max-width: 100px;
    width: 100%;
    height: auto;
  }
  .products-container {
    &.small-width {
      width: calc(70% - 30px);
    }
    &.full-width {
      width: 100%;
    }
    .product-content {
      display: flex;
      padding: 5px;
      img {
        width: 80px;
        max-height: 100px;
        height: auto;
        object-fit: contain;
        padding: 0 10px;
      }
      .product-description {
        .description {
          font-size: 16px;
          text-overflow: ellipsis;
          overflow: hidden;
          word-wrap: break-word;
          white-space: pre-wrap;
        }
      }
    }
    .package-type .product-content img {
      width: 110px;
    }
    .btn-remove {
      background: #c90000;
      width: 19px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 17px;
      }
    }
  }
}
.summary-container {
  width: 30%;
  margin-top: 60px;
  .summary-box {
    border: 1px solid #707070;
    border-radius: 6px;
    padding: 10px;

    .supplier-container {
      .flex {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 0.5rem;
      }

      .credit-limit,
      .discount {
        border-bottom: 1px solid #707070;
      }

      .credit-limit,
      .discount,
      .order-summary {
        margin-bottom: 10px;
        padding: 8px 15px;
      }
    }
  }
}
.remove-all-btn {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  height: 36px;
  font-size: 16px;
  width: auto;
  img {
    height: 70%;
    margin-left: 20px;
    max-width: 20px;
  }
}
/* mobile design start */
.mobile-design {
  display: none;
}
@media (max-width: 480px) {
  .cart-banner {
    .VueCarousel-slide {
      img {
        height: 150px;
      }
    }
  }
  .content-margin {
    margin: 0;
  }
  .destop-design {
    display: none;
  }
  .mobile-design {
    display: block;
    background-color: #fff;
    padding-bottom: 110px;
    .search-block {
      background-color: #002036;
      padding: 5px 10px;
      .input {
        margin: 0 3px;
        border-radius: 5px;
      }
      .primary-btn {
        margin: 0 3px;
        width: 50px;
        height: 38px;
        border-radius: 5px;
      }
    }
    .items-numbers {
      margin: 8px 5px;
    }
    .action-block {
      padding: 5px 10px 0;
      p {
        font-size: 14px;
        text-decoration: underline;
      }
    }
    .company-block {
      border-radius: 10px;
      padding: 5px 0;
      margin: 10px 5px;
      &.shaniv-block {
        border: 1px solid #EC6025;
      }
      &.sastech-block {
        border: 1px solid #2A3B8E;
      }
      .company-logo {
        width: 120px;
        margin: 0 20px;
      }
      .product-block {
        margin: 10px 5px;
        padding: 5px;
        border: 1px solid #D6D6D6;
        border-radius: 10px;
        .product-img {
          height: 60px;
          width: 50px;
          object-fit: contain;
          margin: 0 5px;
        }
        .detail-btn {
          width: 25px;
          height: 25px;
          background-color: #29ABE2;
          border-radius: 5px;
          position: absolute;
          bottom: 10px;
          left: 10px;
        }
        .quantity-block {
          width: 100px;
        }
      }
      .package-block {
        margin: 10px 5px;
        padding: 5px;
        border: 1px solid #2AC0EE;
        border-radius: 10px;
        .package-img {
          width: 60%;
          object-fit: contain;
        }
        .detail-btn {
          position: absolute;
          bottom: 10px;
          left: 10px;
          text-decoration: underline;
          color: #2A3B8E;
          font-size: 14px;
        }
      }
    }
    div.with-out-of-stock {
      background-color: #EB9391;
    }
  }
}

.ltr-type {
  @media (max-width: 480px) {
    .mobile-design { 
      // .summary-modal {
      //   .close-btn {
      //     left: unset;
      //     right: 10px;
      //   }
      // }
      .detail-btn {
        left: unset !important;
        right: 10px;
      }
    }
  }
}
</style>