<template>
  <div class="cart-num"
       :class="{disabled: disabled}">
    <img
      src="/new-assets/icon/plus-circle-solid.svg"
      alt=""
      class="circle-btn plus-circle"
      @click="increase()"
    />
    <input type="text" class="product-num" v-model="number" :disabled="disabled" />
    <img
      src="/new-assets/icon/minus-circle-solid.svg"
      alt=""
      class="circle-btn minus-circle"
      @click="decrease()"
    />
  </div>
</template>

<script>
export default {
  name: 'HcartNum',
  props: {
    num: [Number,String],
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      number: this.num
    }
  },
  methods: {
    increase() {
      if (this.disabled) {
        return;
      }
      this.number += 1;
      this.$emit('increase', this.number);
    },
    decrease() {
      if (this.disabled) {
        return;
      }
      if (this.number > 1) {
        this.number -= 1;
      }
      this.$emit('decrease', this.number);
    },
  }
}
</script>

<style lang="scss" scoped>
  .cart-num {
    position: relative;
    width: 100px;
    display: flex;
    .circle-btn {
      position: absolute;
      background-color: #fff;
      border-radius: 50%;
      cursor: pointer;
    }
    .plus-circle {
      right: 9px;
    }
    .minus-circle {
      left: 9px;
    }
    input {
      background-color: #fff;
      width: 60px;
      text-align: center;
      margin: 0 20px;
      height: 24px;
      font-size: 14px;
      &:focus-visible {
        outline: none;
      }
    }
    &.disabled {
      opacity: 0.6;
      input {
        background-color: #D9D9D9;
      }
    }
  }
</style>